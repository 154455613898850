<template>
  <Container back_ground_color='bg-gray-400'>
      <Card max_width="max-w-4xl">
        <Container class="h-fit space-x-2" flex_direction='flex-row' back_ground_color="bg-white">
          <TextField :id="'fname'" type="text" :placeholder="lang('first.name.textfield')"/>
          <TextField :id="'lname'" type="text" :placeholder="lang('last.name.textfield')"/>
          <TextField :id="'mname'" type="text" :placeholder="lang('middle.name.textfield')"/>
        </Container> 
          <TextField :id="'rank'" type="text" :placeholder="lang('rank.textfield')"/>
          <TextField :id="'bdate'" type="text" :placeholder="lang('birth.date.textfield')"/>
          <TextField :id="'bplace'" type="text" :placeholder="lang('place.of.birth')"/>
        <Container class="h-fit space-x-2" flex_direction='flex-row' back_ground_color="bg-white">
          <TextField :id="'gender'" type="text" :placeholder="lang('gender')"/>
          <TextField :id="'cstatus'" type="text" :placeholder="lang('civil.status')"/>
        </Container> 
        <Container class="h-fit space-x-2" flex_direction='flex-row' back_ground_color="bg-white">
          <TextField :id="'afpserial'" type="text" :placeholder="lang('afp.serial.number')"/>
          <TextField :id="'tin'" type="text" :placeholder="lang('tin')"/>
        </Container> 
          <TextField :id="'bservice'" type="text" :placeholder="lang('branch.of.service')"/>
          <TextField :id="'estatus'" type="text" :placeholder="lang('employee.status')"/>
          <TextField :id="'mobile'" type='mobile' :placeholder="lang('mobile')"/>
          <TextField :id="'email'" type="text" :placeholder="lang('email')"/>
      </Card>
  </Container>
</template>
<script>
import { defineComponent } from 'vue';
import Container from '@/components/sections/ContainerView.vue';
import Card from '@/components/controls/CardView.vue';
import TextField from '@/components/controls/TextBoxField.vue';
import mixin from "@/mixin";

export default defineComponent({
  components:{
    Container,
    Card,
    TextField
  },
   mixins: [
    mixin,
  ],
})
</script>
<style scoped>

</style>
