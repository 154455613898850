import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Locale from "@/locale";
import DashboardView from "@/views/user/DashboardView.vue";
// import ElectionVotingView from "../views/election/ElectionVotingView.vue";
// import ElectionBallotView from "../views/election/ElectionBallotView.vue";
// import ErrorView from "../views/ErrorView.vue";
// import ForgotPasswordView from "../views/forgot-password/ForgotPasswordView.vue";
// import ForgotPasswordOTPValidateView from "../views/forgot-password/ForgotPasswordOTPValidateView.vue";
// import HelpView from "../views/HelpView.vue";
import LoginView from "@/views/LoginView.vue";
// import OurCompanyView from "../views/static/OurCompanyView.vue";
// import HelpCenterView from "../views/static/HelpCenterView.vue";
// import PrivacyPolicyView from "../views/static/PrivacyPolicyView.vue";
import RegisterView from "@/views/registration/RegisterView.vue";
import RegisterValidateView from "@/views/registration/RegisterValidateView.vue";
import RegistrationSuccess from "@/views/registration/RegistrationSuccess.vue";
// import SettingsView from "../views/user/SettingsView.vue";
// import TermsView from "../views/static/TermsView.vue";
// import UpdatePasswordView from "../views/forgot-password/UpdatePasswordView.vue";
// import UserProfileView from "../views/user/UserProfileView.vue";
import MemberApplicationView from "@/views/membership/MembersApplicationView.vue";
// import MemberShipStartup  from "../views/membership/MemberShipStartUpView.vue"
import MembersAddressView  from "../views/membership/MembersAddressView.vue";
// import MemberUploading  from "../views/membership/MemberUploading.vue"
// import MemberConfirmation  from "../views/membership/MemberConfirmation.vue"
// import MemberBranchSelection  from "../views/membership/MemberBranchSelection.vue"
// import MemberEndScreen  from "../views/membership/MemberEndScreen.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    meta: {
      title:"Login",
      auth: false,
      passive: false,
    },
    component: LoginView,
  },
  // {
  //   path: "/help",
  //   name: "help",
  //   meta: {
  //     title: "Help",
  //     auth: true,
  //     passive:false,
  //     navbar:false,
  //   },
  //   component: HelpView,
  // },
  // {
  //   path: "/forgot-password",
  //   name: "forgot-password",
  //   meta: {
  //     title: Locale.get("password.recovery"),
  //     auth: false,
  //     passive: false,
  //   },
  //   component: ForgotPasswordView,
  // },
  // {
  //   path: "/forgot-password/validate",
  //   name: "forgot-password-validate",
  //   meta: {
  //     title: 'ONE TIME PIN',
  //     auth: false,
  //     passive: false,
  //   },
  //   component: ForgotPasswordOTPValidateView,
  // },
  // {
  //   path: "/forgot-password/update",
  //   name: "update-password",
  //   meta: {
  //     title: 'Update Password',
  //     auth: false,
  //     passive: false,
  //   },
  //   component: UpdatePasswordView,
  // },
  {
    path: "/register",
    name: "register",
    meta: {
      title: Locale.get('create.an.account'),
      auth: false,
      passive: false,
    },
    component: RegisterView,
  },
  {
    path: "/register/validate",
    name: "register-validate",
    meta: {
      title: 'One Time Pin',
      auth: false,
      passive: false,
    },
    component: RegisterValidateView,
  },
  {
    path: "/register/success",
    name: "RegisterSuccess",
    meta: {
      title: Locale.get('success'),
      auth: false,
      passive: false,
    },
    component: RegistrationSuccess,
  },
  // {
  //   path: "/end-user-license-agreement",
  //   name: "end-user-license-agreement",
  //   meta: {
  //     title: 'End User License Agreement',
  //     auth: false,
  //     passive: true,
  //   },
  //   component: TermsView,
  // },
  // {
  //   path: "/privacy-policy",
  //   name: "privacy-policy",
  //   meta: {
  //     title: Locale.get("privacy.policy"),
  //     auth: false,
  //     passive: true,
  //   },
  //   component: PrivacyPolicyView,
  // },
  // {
  //   path: "/our-company",
  //   name: "our-company",
  //   meta: {
  //     title: Locale.get("our.company"),
  //     auth: false,
  //     passive: true,
  //     navbar:true,
  //   },
  //   component: OurCompanyView,
  // },
  // {
  //   path: "/help-center",
  //   name: "help-center",
  //   meta: {
  //     title: "Help Center",
  //     auth: false,
  //     passive: false,
  //   },
  //   component: HelpCenterView,
  // },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: Locale.get("dashboard"),
      auth: true,
      passive: true,
      navbar:true,
    },
    component: DashboardView,
  },
  // {
  //   path: "/election/start",
  //   name: "election-start",
  //   meta: {
  //     title: 'Voter OTP',
  //     auth: true,
  //     passive: false,
  //     navbar: false,
  //   },
  //   component: ElectionVotingView,
  // },
  // {
  //   path: "/election/ballot",
  //   name: "election-ballot",
  //   meta: {
  //     title: 'Election Ballot',
  //     auth: true,
  //     passive: false,
  //     navbar: true,
  //   },
  //   component:ElectionBallotView
  //   ,
  // },
  // {
  //   path: "/election/voting",
  //   name: "election-voting",
  //   meta: {
  //     title: 'Voting Screen',
  //     auth: true,
  //     passive: false,
  //     navbar: false,
  //   },
  //   component: ElectionVotingView,
  // },
  // {
  //   path: "/user/settings",
  //   name: "settings",
  //   meta: {
  //     title: Locale.get('user.settings'),
  //     auth: true,
  //     passive: true,
  //     navbar:true
  //   },
  //   component: SettingsView,
  // },
  {
    path: "/member/application",
    name: "MemberShip",
    meta: {
      title: "Membership Application",
      auth: false,
      passive: false,
    },
    component: MemberApplicationView,
  }, 
  {
    path: "/member/address",
    name: "MembershipAddress",
    meta: {
      title: "Membership Application",
      auth: false,
      passive: false,
    },
    component: MembersAddressView,
  },
  // {
  //   path: "/member/startup",
  //   name: "MemberShipStartup",
  //   meta: {
  //     title: "Choose Application Type",
  //     auth: false,
  //     passive: false,
  //   },
  //   component: MemberShipStartup,
  // },
  // {
  //   path: "/member/uploading",
  //   name: "MemberShipUploading",
  //   meta: {
  //     title: "Membership Application",
  //     auth: false,
  //     passive: false,
  //   },
  //   component: MemberUploading,
  // },
  // {
  //   path: "/member/confirmation",
  //   name: "member-confirmation",
  //   meta: {
  //     title: 'One Time Pin',
  //     auth: false,
  //     passive: false,
  //   },
  //   component: MemberConfirmation,
  // },
  // {
  //   path: "/member/BranchSelection",
  //   name: "member-branch-selection",
  //   meta: {
  //     title: 'Branch Selection',
  //     auth: false,
  //     passive: false,
  //   },
  //   component: MemberBranchSelection,
  // },
  // {
  //   path: "/member/End-Screen",
  //   name: "memberEnd-Screen",
  //   meta: {
  //     title: 'Congratulations',
  //     auth: false,
  //     passive: true,
  //   },
  //   component: MemberEndScreen,
  // },
  
  // {
  //   path: "/user/:id",
  //   name: "profile",
  //   meta: {
  //     title: "User Profile",
  //     auth: true,
  //     passive: false,
  //   },
  //   component: UserProfileView,
  // },
  
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "error",
  //   meta: {
  //     title: Locale.get('page.not.found'),
  //     auth: false,
  //     passive: true,
  //   },
  //   component: ErrorView,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to) => {
  const title = to.meta.title as any;
  document.title = title?.toString();
});

export default router;
