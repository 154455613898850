<template>
  <Container back_ground_color='bg-gray-400 p-20'>
      <ConfigProvider :theme="this.token"
    >
        <Steps :current="this.current" id="step">
          <a-step v-for="item in steps" :key="item.title" :title="item.title" @click="change(item.content)"/>
        </Steps>
      </ConfigProvider>
      <BasicInfo v-show="this.basic_info" class="transition delay-100 duration-500 ease-in-out" 
      v-bind:class="[{'-translate-x-0' : this.show_step_one ,'-translate-x-full':!this.show_step_one}]"/>
      <Address v-show="this.address"  class="transition delay-100 duration-500 ease-in-out" 
      v-bind:class="[{'-translate-x-0' : this.show_step_two ,'-translate-x-full':!this.show_step_two}]"/>
      <Uploading v-show="this.uploading" class="transition delay-100 duration-500 ease-in-out" 
      v-bind:class="[{'-translate-x-0' : this.show_step_three ,'-translate-x-full':!this.show_step_three}]"/>
      <div class="flex justify-end">
         <Button :max_width="'max-w-xxs'" :button_text="'Next'" @clicked="nextStep"/>
       </div>
  </Container>
</template>
<script>
import { defineComponent } from 'vue';
import BasicInfo from "./MembersBasicInfoView.vue";
import Address from "./MembersAddressView.vue";
import Uploading from "./MembersUploading.vue";
import Button from "@/components/controls/ButtonControl.vue"
import Container from '@/components/sections/ContainerView.vue';
import mixin from "@/mixin";
import {Steps,ConfigProvider } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

export default defineComponent({
  components:{
    Container,
    BasicInfo,
    Address,
    ConfigProvider,
    Uploading,
    Steps,
    Button,
  },
  mixins: [
    mixin,
  ],
  mounted(){
    this.basic_info=true;
  },
  data(){
    return{
      token:{
        colorPrimary:"#016b00"
      },
      current:0,
      basic_info:false,
      show_step_one:false,
      show_step_two:false,
      show_step_three:false,
      step_one_done:false,
      step_step_two:false,
      step_step_three:false,
      completed:false,
      address:false,
      uploading:false,
      steps:[
            {
              title:"Personal Information",
              content:"bsinfo",
              active:true,
            },
            {
              title:"Permanent & Secondary Address",
              content:"secinfo",
              active:true,
            },
            {
              title:"Parents Info & Uploading",
              content:"uploading",
              active:true,
            },
          ]
    }
  },
  methods:{
    change(content){
       if(content=='bsinfo' && this.step_one_done){
          this.current=0;
          this.basic_info=true;
          this.address=false;
          this.uploading=false;
        }
        if(content=='secinfo' && this.step_two_done){
          this.current=1;
          this.basic_info=false;
          this.address=true;
          this.uploading=false;
        }
        if(content=='uploading' && this.step_three_done) {
          this.current=2;
          this.basic_info=false;
          this.address=false;
          this.uploading=true;
        }
    },
    nextStep(){
        let current_step='';
        
        if(this.current<this.steps.length){
           this.current++;
        }
        if(!this.completed){
          this.steps[this.current].active;
          current_step=this.steps[this.current].content;
        }
        if(current_step=='bsinfo') {
          this.basic_info=true;
          this.address=false;
          this.uploading=false;
        }
        if(current_step=='secinfo'){
          this.basic_info=false;
          this.address=true;
          this.uploading=false;
          this.step_one_done=true;
        }
        if(current_step=='uploading'){
          this.basic_info=false;
          this.address=false;
          this.uploading=true;
          this.step_two_done=true;
        }
        if(this.current==2){
          this.step_three_done=true;
          this.completed=true;
        }
    }
  },
  watch:{
    basic_info(n){
            setTimeout(() => {
                this.show_step_one = n;
        }, 10);
    },
    address(n){
            setTimeout(() => {
                this.show_step_two = n;
        }, 10);
    },
    uploading(n){
          setTimeout(() => {
                this.show_step_three = n;
        }, 10);
    }
  }

})
</script>
<style scoped>

</style>
