<template>
 <Container back_ground_color='bg-gray-400'>
      <Card max_width="max-w-4xl">
          <TextField :id="'email'" type="text" :placeholder="lang('email')"/>
      </Card>
  </Container>
</template>
<script>
import { defineComponent } from 'vue'
import Container from '@/components/sections/ContainerView.vue';
import Card from '@/components/controls/CardView.vue';
import TextField from '@/components/controls/TextBoxField.vue';
import mixin from "@/mixin";

export default defineComponent({
  components: {
    Container,
    Card,
    TextField,
  },
  data(){
    return {
     
    }
  },
 mixins: [
    mixin,
  ],
  methods:{
    
  }
})
</script>
