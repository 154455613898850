<template>
    <Container heigh="h-14">
        <div class="h-14 bg-theme-primary shadow-md flex items-center p-2" >
        <div class="text-white " @click="clicked">
            trigger
        </div>
        </div>
    </Container>
</template>
<script>
import { defineComponent } from 'vue';
import Container from '@/components/sections/ContainerView.vue';

export default defineComponent({
    components:{
        Container
    },
    methods:{
        clicked(){
            this.$emit('sidebar');
        }
    }
})
</script>
