import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ant from 'ant-design-vue';
import cookie from 'vue-cookies';
import "./assets/tailwind.css";
import VueTheMask from 'vue-the-mask'
import vSelect from "vue-select";

createApp(App).use(store).use(cookie).use(router).use(ant).component("v-select", vSelect).use(VueTheMask).mount("#app");
