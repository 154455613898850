export default {
    'username.header':'USERNAME',
    'password.header':'PASSWORD',
    'sign.up.link.text':'Sign Up',
    'need.help.link.text':'Need Help?',
    'be.a.member.now':'Not A Member Yet?',
    'copy.right.text':'PAFCPIC © 2023',
    'login.text':'LOGIN',
    'privacy.policy.header.text': 'PRIVACY POLICY',
    'terms.header.text':'TERMS OF USE',
    'contact.header.text': 'CONTACT US',
    'facebook.text':'https://www.facebook.com/pafcpic',
    'instagram.text':'https://www.instagram.com/pafcpicofficial',
    'create.an.account': 'Create an Account',
    'i.agree.with.the': 'I agree with the',
    'end.user.license.agreement':'End User License Agreement',
    'privacy.policy':'Privacy Policy.',
    'proceed': 'Proceed',
    'already.have.an.account':'Already Have an Account? Back to Login',
    'one.time.pin':'One Time Pin',
    'otp.message':'Please enter the one time pin we have sent to your email or mobile number so that we can verify your identity.',
    'submit':'SUBMIT',
    'member.application.card.header': 'Application Form',
    'first.name.textfield': 'First Name',
    'last.name.textfield':'Last Name',
    'middle.name.textfield':'Middle Name',
    'rank.textfield':'Rank',
    'birth.date.textfield':'Birth Date',
    'place.of.birth':'Place of Birth',
    'gender':'Gender',
    'civil.status':'Civil Status',
    'afp.serial.number':'AFP Serial Number',
    'branch.of.service':'Branch of Service',
    'employee.status':'Employee Status',
    'tin':'TIN',
    'mobile':'Mobile Number',
    'email':'Email Address',
    'personal.info':'Personal Information',
    'permanent.and.secondary.address':'Permanent & Secondary Address',
    'parents.info.and.ploading':'Parents Info & Uploading',
    'permanent.address':'PERMANENT ADDRESS',
    'secondary.address':'SECONDARY ADDRESS',
    'street.address':'Street Address',
    'barangay':'Barangay',
    'zip.code':'Zip Code',
    'province':'Province',
    'city':'City',
    'search':'SEARCH',
    'by.credentials':'Username, Account No., Serial No., Email, Mobile No.',
    'by.account.number':'Account No.',
    'by.serial.number':'Serial No.',
    'registration.title':'Registration',
    'register.button.text':'REGISTER',
    'view.amendments':'READ THE PROPOSE AMENDMENTS TO THE PAFCPIC ARTICLES OF COOPERATION',
    'amendments.title':'PAFCIPIC',
    'enter.code':'ENTER CODE',
    'confirmation.title.modal':'CONFIRMATION',
    'checkbox.label.yes':'APPROVED',
    'checkbox.label.no':'DISAPPROVED',
    'qr.card.label':'SCAN QR',
    'letter.greetings':'Dear Fellow Member,',
    'letter.first.para':'The Board of Directors is proposing for the approval of the attached amendments to PAFCPIC’s Articles of Cooperation and By-laws.',
    'letter.second.para':'Justification: To conform with the provisions of RA 9520 or the Philippine Cooperative Code of 2008 and its Implementing Rules and Regulations; and to correct typographical and grammatical error.',
    'letter.casting.vote':'To cast your vote, you may check the appropriate box below.',
    'letter.acknowledging.para':'I herby cast my vote on the proposed amendments to the PAFCPIC Articles of Cooperation and By-laws, as indicated below with a check mark',
    'username.instructions':'Username must be at least six characters and must not contain spaces and special characters.',
    'password.instructions':'Use eight or more characters with a mix of letters, numbers and symbols.',
    'total.members':'TOTAL MEMBERS',
    'total.votes.mobile':'TOTAL MOBILE',
    'total.votes.web':'TOTAL WEB',
    'total.votes.onsite':'TOTAL ONSITE',
    'total.votes.mev':'TOTAL MEV',
    'total.votes.non.mev':'TOTAL NON-MEV',
    
}