<template>
    <div class="border-4 bg-white rounded shadow-lg w-full" :class="[max_width,background]">
        <div class="w-full" v-if="Logo">
             <img src="https://pafcpic.ph/sites/all/themes/pafcpic/logo.png" class="w-full p-5" />
        </div>
        <div v-if="title" class="border-b border-gray-200 p-3 font-bold text-xl">
            <span v-text="title_morph()"/>
        </div>
        <div class=" w-full flex place-content-center h-full" :class="direction">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
 props:{
    max_width: {
        type: String,
        required: false,
    },
    title:{
        type:String,
        required:false,
    },
    Logo: {
        type:Boolean,
        required:false,
    },
    background:{
        type:String,
        required:false,
    },
    direction:{
        type:String,
        required:false,
    }
 },
 methods:{
    title_morph() {
        return this.title.toUpperCase();
    }
 }
})
</script>
<style scoped>

</style>
