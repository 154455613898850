<template>
  <ContainerView back_ground_color="bg-white pb-14">
    <Modal v-show="show_branches_modal" 
      v-bind:display="show_branches_modal"
      title="SATELLITE OFFICES"
      z_value="z-50"
      closable
      @closed="closed"
      :default_width="'max-w-7xl'"
      id='vote_modal'>
      <div class="w-full p-5 flex flex-col text-xl font-bold shadow-md">
         <Bar v-if="branches" :data="branch_chartConfig" :options="branch_options" ref="chart1" />
      </div>
    </Modal>
    <div class="p-5 w-full ">
      <div class="bg-white w-full p-2 flex flex-row border shadow-md border-gray-400 rounded">
        <div class="max-button-width">
          <!--  getStats-->
          <Button :button_text="'REFRESH'" @clicked="getStats"/>
        </div>
        <div  class="w-full text-center font-bold text-2xl self-center">
          <span v-text="'AS OF ' + as_of_date_time" v-show="current_timestamp" class="w-full"/>
        </div>
        <div class=" flex place-content-end">
          <div class="max-button-width">
            <Button :button_text="'LOGOUT'" :theme="'bg-red-600'" @clicked="logout"/>
          </div>
        </div>
      </div>
    </div>
    <div class="p-5 w-full relative mb-5">
      <div class="absolute -mt-7 text-3xl font-bold ml-10 text-theme-primary rounded p-2 bg-white">
        <span v-text="'TARGET STATUS'"/>
      </div>
      <div class="bg-white w-full space-y-3 font-bold text-2xl p-5 border shadow-md border-gray-400 rounded">
        <div class="w-full flex justify-end">
         <span v-text="target_votes.toLocaleString('en-US') " class="ml-5 mt-5 font-bold text-2xl text-green-700"/>
        </div> 
          <div class="flex flex-row">
            <span v-text="'REGULAR MEMBERS'" class="w-full max-progress-width"/>
            <div class="w-full flex flex-row space-x-4 border-2 rounded">
            <div class="bg-yellow-400 rounded text-center p-1 w-full" :style="{width: (target_status_mev/target_votes)*100+'%'}">{{target_status_mev.toLocaleString('en-US')}}</div>
          </div>
         </div>
         <div class="flex flex-row">
          <span v-text="'ASSOCIATE MEMBERS'" class="w-full max-progress-width"/>
         <div class="w-full flex flex-row space-x-4 border-2 rounded">
           <div class="bg-blue-700 rounded text-center p-1 w-full" :style="{width: (target_status_non_mev/target_votes)*100+'%'}">{{target_status_non_mev.toLocaleString('en-US')}}</div>
         </div>
         </div>
         <div class="flex flex-row">
          <span v-text="'TOTAL'" class="w-full max-progress-width"/>
         <div class="w-full flex flex-row space-x-4 border-2 rounded">
           <div class="bg-green-700 text-center p-1 w-full" :style="{width: ((target_status_mev +target_status_non_mev)/target_votes)*100+'%'}">{{(target_status_mev +target_status_non_mev).toLocaleString('en-US')}}</div>
         </div>
         </div>
      </div>
    </div>
    <div class="w-full h-full flex flex-col p-5 text-left relative ">
      <div class="absolute text-3xl font-bold -mt-7 z-40 ml-10 text-theme-primary rounded p-2 bg-white">
            <span v-text="'REGULAR MEMBERS'" />
          </div>
      <div class="flex flex-row xs:flex-col p-20 bg-white border shadow-md border-gray-400 rounded relative">
          <div class="w-full max-w-md bg-white border shadow-md rounded p-5 max-h-fit xs:self-center">
            <Doughnut :width="20" :height="40" :data="doughnut_config_regular" :options="doughnut_options" />
          </div>
          <div class="flex flex-col w-full justify-center xs:ml-0  ml-4">
          <div class="shadow-md minimum-small">
            <div class="w-full grid grid-cols-5">
                <div class="p-4 text-lg border background-regular border-black"></div>
                <div class="p-4 text-center text-2xl text-white font-bold border border-black background-onsite">ONSITE</div>
                <div class="p-4 text-center text-2xl text-white font-bold border border-black background-mobile">MOBILE</div>
                <div class="p-4 text-center text-2xl text-white font-bold border border-black background-web">WEB</div>
                <div class="p-4 text-center text-2xl text-white font-bold border border-black bg-theme-primary">TOTAL</div>
            </div>
            <div class="w-full grid grid-cols-5">
                <div class="border  p-4 text-lg font-bold background-regular border-black">APPROVED</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{approve_onsite.toLocaleString('en-US')}}</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{approve_mobile.toLocaleString('en-US')}}</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{approve_web.toLocaleString('en-US')}}</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{(approve_onsite +  approve_web + approve_mobile).toLocaleString('en-US')}}</div>
            </div>
            <div class="w-full grid grid-cols-5">
                <div class="border  p-4 text-lg font-bold background-regular border-black">DISAPPROVED</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{disapprove_onsite.toLocaleString('en-US')}}</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{disapprove_mobile.toLocaleString('en-US')}}</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{disapprove_web.toLocaleString('en-US')}}</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{(disapprove_mobile+disapprove_web+disapprove_onsite).toLocaleString('en-US')}}</div>
            </div>
            <div class="w-full grid grid-cols-5">
                <div class="border  p-4 text-lg font-bold background-regular border-black">TOTAL</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{(approve_onsite+disapprove_onsite).toLocaleString('en-US')}}</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{(approve_mobile+disapprove_mobile).toLocaleString('en-US')}}</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{(approve_web+disapprove_web).toLocaleString('en-US')}}</div>
                <div class="border  p-4 text-center text-lg font-bold border-black background-regular">{{(disapprove_mobile+disapprove_web+disapprove_onsite + approve_onsite + approve_web + approve_mobile).toLocaleString('en-US')}}</div>
            </div>
          </div>
        </div>
      </div>
      
        <div class="flex flex-row xs:p-2 p-20 mt-14 bg-white border shadow-md border-gray-400 rounded relative">
          <div class="absolute text-3xl font-bold -mt-28 xs:-mt-9 xs:ml-5 -ml-10 z-40 text-theme-primary rounded p-2 bg-white">
            <span v-text="'ASSOCIATE MEMBERS'" />
          </div>
          <div class="flex flex-row xs:flex-col p-20 bg-white w-full border-gray-400 rounded relative">
            <div class="w-full max-w-md bg-white border shadow-md rounded p-5 max-h-fit xs:self-center">
              <Doughnut :width="20" :height="40" :data="doughnut_config_associates" :options="doughnut_options" />
            </div>
              <div class="flex flex-col w-full justify-center xs:ml-0 ml-4 minimum-small">
                <div class="shadow-md">
                  <div class="w-full grid grid-cols-5">
                    <div class="p-4 text-lg border background-regular border-black"></div>
                    <div class="p-4 text-center text-2xl text-white border-black font-bold border  background-onsite">ONSITE</div>
                    <div class="p-4 text-center text-2xl text-white border-black font-bold border  background-mobile">MOBILE</div>
                    <div class="p-4 text-center text-2xl text-white border-black font-bold border  background-web">WEB</div>
                    <div class="p-4 text-center text-2xl text-white border-black font-bold border  bg-theme-primary">TOTAL</div>
                </div>
                <div class="w-full grid grid-cols-5">
                    <div class="border p-4 text-lg font-bold background-regular border-black">REGISTERED</div>
                    <div class="border p-4 text-center text-lg border-black background-regular font-bold">{{non_mev_onsite.toLocaleString('en-US')}}</div>
                    <div class="border p-4 text-center text-lg border-black background-regular font-bold">{{non_mev_mobile.toLocaleString('en-US')}}</div>
                    <div class="border p-4 text-center text-lg border-black background-regular font-bold">{{non_mev_web.toLocaleString('en-US')}}</div>
                    <div class="border p-4 text-center text-lg border-black background-regular font-bold">{{(non_mev_mobile +  non_mev_web + non_mev_onsite).toLocaleString('en-US')}}</div>
                </div>
                <div class="w-full grid grid-cols-5">
                    <div class="border  p-4 text-lg font-bold background-regular border-black">TOTAL</div>
                    <div class="border  p-4 text-center text-lg border-black font-bold background-regular">{{non_mev_onsite.toLocaleString('en-US')}}</div>
                    <div class="border  p-4 text-center text-lg border-black font-bold background-regular">{{non_mev_mobile.toLocaleString('en-US')}}</div>
                    <div class="border  p-4 text-center text-lg border-black font-bold background-regular">{{non_mev_web.toLocaleString('en-US')}}</div>
                    <div class="border  p-4 text-center text-lg border-black font-bold background-regular">{{(non_mev_mobile +  non_mev_web + non_mev_onsite).toLocaleString('en-US')}}</div>
                    </div>
            </div>
            </div>
          </div>
        </div>
      <div class="w-full p-10 flex flex-col space-x-5 bg-white border shadow-md border-gray-400 rounded relative mt-14 mb-14">
         <div class="absolute text-3xl font-bold -mt-20 -ml-2  text-theme-primary rounded p-5 bg-white">
            <span v-text="'VOTE DETAILS'" />
          </div>
          <!-- <div class="w-full flex flex-row">
            <div class="w-full rounded max-w-7xl mb-2 flex flex-row space-x-2">
              <Button button_text="OVER ALL"/>
              <Button button_text="1ST WEEK"/>
              <Button button_text="2ND WEEK" :disabled="disable_second_week"/>
              <Button button_text="3RD WEEK" :disabled="disable_third_week"/>
              <Button button_text="4TH WEEK" :disabled="disable_fourth_week"/>
              <Button button_text="5TH WEEK" :disabled="disable_fifth_week"/>
              <Button button_text="6TH WEEK" :disabled="disable_sixth_week"/>
              <Button button_text="7TH WEEK" :disabled="disable_seventh_week"/>
            </div>
            <div class="w-full max-w-2xl p-1"></div>
          </div> -->
         <div class="flex flex-row w-full xs:flex-col"> 
          <div class="w-full bg-white border shadow-md rounded p-5 max-h-fit max-w-2xl xs:max-w-md self-center">
            <Doughnut :width="200" :height="380" :data="doughnutConfig" :options="doughnut_options" />
          </div>
          <div class="w-full bg-white border shadow-md rounded p-5 max-h-fit max-w-7xl">
            <Bar v-if="over_all" :data="chartConfig" :options="options" ref="chart1" />
          </div>
        </div>
      </div>
      <div class="w-full p-10 flex flex-col space-x-5 bg-white border shadow-md border-gray-400 rounded relative mt-2">
         <div class="absolute text-3xl font-bold -mt-20 -ml-2  text-theme-primary rounded p-5 bg-white">
            <span v-text="'PSO SUMMARY'" />
          </div>
          <div class="w-full">
              <div class="border border-black w-full grid grid-cols-9">
                <div class="w-full xs:col-span-4  col-span-1 p-2 self-center border-r text-center background-onsite">
                  <span v-text="'PSO'" class="font-bold text-lg w-full minimum-table"/>
                </div>
                <div class="col-span-3 xs:col-span-2 border-l border-r border-black w-full flex flex-row p-3 text-xl">
                  
                </div>
                 <div class="w-full col-span-1 p-2 self-center border-r border-black text-center bg-theme-primary">
                  <span v-text="'APPROVED'" class="text-lg font-bold"/>
                </div>
                <div class="w-full col-span-1  p-2 self-center border-r border-black text-center bg-theme-error-active">
                  <span v-text="'DISAPPROVED'" class="text-lg font-bold"/>
                </div>
                <div class="w-full col-span-1  p-2 self-center border-r border-black text-center bg-blue-400">
                  <span v-text="'REGULAR'" class="text-lg font-bold"/>
                </div>
                 <div class="w-full col-span-1  p-2 self-center border-r border-black text-center bg-purple-400">
                  <span v-text="'ASSOCIATE'" class="text-lg font-bold"/>
                </div>
                <div class="w-full col-span-1  p-2 self-center border-r border-black text-center bg-theme-secondary">
                  <span v-text="'TOTAL'" class="text-lg font-bold"/>
                </div>
              </div>
              <div v-for="items in pso_details" :id="items.name" :key="items.code" class="border border-black w-full grid grid-cols-9">
                <div class="w-full xs:col-span-4 col-span-1 self-center pl-2 ">
                  <span v-text="items.name" class="font-bold text-lg w-full "/>
                </div>
                <div class="col-span-3 xs:col-span-2 border-l border-r items-center border-black w-full flex flex-row p-3 text-xl">
                  <div class="border rounded w-full flex flex-row h-8">
                    <div class="bg-green-700 text-center w-full  text-white font-bold min-w-md" :style="{width: ((items.total_approved)/(target_status_mev))*100+'%'}"></div>
                    <div class="bg-theme-error-active text-center w-full text-white font-bold min-w-md" v-show="items.total_disapproved>0"  :style="{width: ((items.total_disapproved)/(target_status_mev))*100+'%'}"></div>
                     
                    <div class="bg-purple-400 text-center w-full text-white font-bold min-w-md" :style="{width: ((items.total_registered-items.total_voted)/(target_status_mev))*100+'%'}"></div>
                    <!-- <span v-text="'Total Approved: ' +items.total_approved" class="text-lg"/>
                    <span v-text="'Total Disapproved: '+items.total_disapproved" class="text-lg"/> -->
                  </div>
                </div>
                 <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="items.total_approved.toLocaleString('en-US')" class="text-lg self-center w-full "/>
                </div>
                <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="items.total_disapproved.toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
                <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="items.total_voted.toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
                <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="(items.total_registered-items.total_voted).toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
                <div class="w-full col-span-1 flex text-center">
                  <span v-text="(items.total_registered).toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
              </div>
              <div class="border border-black w-full grid grid-cols-9">
                <div class="w-full xs:col-span-4 col-span-1 self-center pl-2 ">
                  <span v-text="'MOBILE'" class="font-bold text-lg w-full "/>
                </div>
                <div class="col-span-3 xs:col-span-2 border-l border-r border-black w-full flex flex-row p-3 text-xl">
                  <div class="border rounded w-full flex flex-row h-8">
                    <div class="bg-green-700 text-center w-full text-white font-bold min-w-md" :style="{width: ((this.approve_mobile)/(target_status_mev))*100+'%'}"></div>
                    <div class="bg-theme-error-active text-center w-full text-white font-bold min-w-md" v-show="this.disapprove_mobile>0"  :style="{width: ((this.disapprove_mobile)/(target_status_mev))*100+'%'}"></div>
                    <div class="bg-purple-400 text-center w-full text-white font-bold min-w-md" :style="{width: ((this.non_mev_mobile)/(target_status_mev))*100+'%'}"></div>
                    <!-- <span v-text="'Total Approved: ' +items.total_approved" class="text-lg"/>
                    <span v-text="'Total Disapproved: '+items.total_disapproved" class="text-lg"/> -->
                  </div>
                </div>
                 <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="this.approve_mobile.toLocaleString('en-US')" class="text-lg self-center w-full "/>
                </div>
                <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="this.disapprove_mobile.toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
                  <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="(this.approve_mobile+this.disapprove_mobile).toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
                <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="this.non_mev_mobile.toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
                <div class="w-full col-span-1 flex text-center">
                  <span v-text="(this.approve_mobile+this.disapprove_mobile+this.non_mev_mobile).toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
              </div>
              <div class="border border-black w-full grid grid-cols-9">
                <div class="w-full xs:col-span-4 col-span-1 self-center pl-2 ">
                  <span v-text="'WEB'" class="font-bold text-lg w-full "/>
                </div>
                <div class="col-span-3 xs:col-span-2 border-l border-r border-black w-full flex flex-row p-3 text-xl">
                  <div class="border rounded w-full flex flex-row h-8">
                    <div class="bg-green-700 text-center w-full text-white font-bold min-w-md" :style="{width: ((this.approve_web)/(target_status_mev))*100+'%'}"></div>
                    <div class="bg-theme-error-active text-center w-full text-white font-bold min-w-md" v-show="this.disapprove_web>0"  :style="{width: ((this.disapprove_web)/(target_status_mev))*100+'%'}"></div>
                    <div class="bg-purple-400 text-center w-full text-white font-bold min-w-md" :style="{width: ((this.non_mev_web)/(target_status_mev))*100+'%'}"></div>
                    <!-- <span v-text="'Total Approved: ' +items.total_approved" class="text-lg"/>
                    <span v-text="'Total Disapproved: '+items.total_disapproved" class="text-lg"/> -->
                  </div>
                </div>
                 <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="this.approve_web.toLocaleString('en-US')" class="text-lg self-center w-full "/>
                </div>
                <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="this.disapprove_web.toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
                <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="(this.approve_web+this.disapprove_web).toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
                 <div class="w-full col-span-1 flex border-r border-black text-center">
                  <span v-text="this.non_mev_web.toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
                <div class="w-full col-span-1 flex text-center">
                  <span v-text="(this.approve_web+this.disapprove_web+this.non_mev_web).toLocaleString('en-US')" class="text-lg self-center w-full"/>
                </div>
              </div>
              <div class="border border-black w-full grid grid-cols-9">
                <div class="w-full col-span-1 xs:col-span-4 text-center self-center pl-2 p-2 xs:minimum-table">
                  <span v-text="'GRAND TOTAL'" class="font-bold text-lg w-full "/>
                </div>
                <div class="col-span-3 border-l xs:col-span-2 border-r border-black w-full flex flex-row p-3 text-xl ">
                  
                </div>
                 <div class="w-full col-span-1 flex border-r border-black text-center p-2">
                  <span v-text="(approve_onsite+approve_mobile+approve_web).toLocaleString('en-US')" class="text-lg self-center font-bold w-full "/>
                </div>
                <div class="w-full col-span-1 flex border-r border-black text-center ">
                  <span v-text="(disapprove_onsite+disapprove_mobile+disapprove_web).toLocaleString('en-US')" class="text-lg self-center font-bold w-full"/>
                </div>
                 <div class="w-full col-span-1 flex border-r border-black text-center ">
                  <span v-text="(disapprove_onsite+disapprove_mobile+disapprove_web+approve_onsite+approve_mobile+approve_web).toLocaleString('en-US')" class="text-lg self-center font-bold w-full"/>
                </div>
                <div class="w-full col-span-1 flex border-r border-black text-center ">
                  <span v-text="(non_mev_mobile+non_mev_web+non_mev_onsite).toLocaleString('en-US')" class="text-lg self-center font-bold w-full"/>
                </div>
               
                <div class="w-full col-span-1 flex text-center">
                  <span v-text="(approve_onsite+disapprove_onsite+disapprove_mobile+disapprove_web+approve_mobile+approve_web+non_mev_mobile+non_mev_web+non_mev_onsite).toLocaleString('en-US')" class="text-lg self-center font-bold w-full"/>
                </div>
               
              </div>
          </div>
      </div>
      <div class="p-3 w-full"></div>
    </div>

  </ContainerView>
</template>

<script>
import { defineComponent } from 'vue';
import ContainerView from '@/components/sections/ContainerView.vue';
import Button from "@/components/controls/ButtonControl.vue";
// import CardView from "@/components/controls/CardView.vue";
// import Icon from "@/components/controls/Icon.vue";
import election from "@/services/Election";
import Modal from "@/components/controls/ModalControl.vue";
import mixin from "@/mixin";
import { Chart as ChartJS, ArcElement, Tooltip, Legend ,BarElement,
  CategoryScale,Title,
  LinearScale } from 'chart.js'
import { Bar,Doughnut } from 'vue-chartjs'
import moment from 'moment';

ChartJS.register(ArcElement, Tooltip,Title, Legend,BarElement,CategoryScale,LinearScale)


export default defineComponent({
    components:{
      ContainerView,
      // Icon,
      // CardView,
      Button,
      Bar,
      Doughnut,
      Modal,
    },
    mounted() {
      let that = this;
      const cookie = that.$cookies;
      console.log(cookie.get('user'));
      if(cookie.get('user') == null){
        that.$router.push('/');
      } else {
        this.getStats();
      }
      
    },
     data(){
      return {
        show_branches_modal: false,
        show_message_modal:false,
        modal_message:'',
        as_of_date_time:'',
        break_down:false,
        error:false,
        success:false,
        warning:false,
        loading:false,
        first_week:false,
        second_week:false,
        third_week:false,
        fourth_week:false,
        fifth_week:false,
        over_all:false,
        branches:false,
        disable_second_week:true,
        disable_third_week:true,
        disable_fourth_week:true,
        disable_fifth_week:true,
        disable_sixth_week:true,
        disable_seventh_week:true,
        current_timestamp:null,
        pso_details:[],
        first_week_config:{},
        second_week_config:{},
        third_week_config:{},
        fourth_week_config:{},
        fifth_week_config:{},
        doughnutConfig: {
          labels: [ 'TOTAL APPROVED', 'TOTAL DISAPPROVED'],
          datasets: [ { data: [0,0],
          backgroundColor: ['#016b00', '#A70000'] 
          } ]
        },
        doughnut_config_regular: {
          labels: [ 'ONSITE', 'MOBILE','WEB'],
          datasets: [ { data: [0,0,0],
          backgroundColor: ['#016b00', '#FBD603','#A70000'] 
          } ]
        },
        doughnut_config_associates:{
           labels: [ 'ONSITE', 'MOBILE','WEB'],
          datasets: [ { data: [0,0,0],
          backgroundColor: ['#016b00', '#FBD603','#A70000'] 
          } ]
        },
        chartConfig: {
          labels: [],
          datasets: [
              { 
                label: "VOTED",
                data: [],
                backgroundColor: ['#016b00']
              },
              { 
                label: ["REGISTERED"],
                data: [],
                backgroundColor: ['#FBD603']
              },
            ]
        },
        branch_chartConfig: {
          labels: [],
          datasets: [
              { 
                label: "APPROVED",
                data: [],
                backgroundColor: ['#016b00']
              },
              { 
                label: ["DISAPPOVED"],
                data: [],
                backgroundColor: ['#A70000']
              },
            ]
        },
        branch_options: {
          responsive: true,
        },
        doughnut_options:{
          responsive: true,
        },
        options: {
          responsive: true,
           onClick: (event, item) => { 
                const chartInstance = item;
                
                console.log(this.chartConfig.labels[chartInstance[0].index]);
                let payload ={
                  date:this.chartConfig.labels[chartInstance[0].index],
                  // timestamp:this.current_timestamp
                }
                this.getStatBranches(payload);
            }
        },
        total_mev:0,
        target_status_mev:0,
        target_status_non_mev:0,
        total_members:0,
        total_mobile:0,
        total_web:0,
        total_onsite:0,
        approve_mobile:0,
        approve_web:0,
        approve_onsite:0,
        disapprove_mobile:0,
        disapprove_web:0,
        disapprove_onsite:0,
        non_mev_mobile:0,
        non_mev_web:0,
        non_mev_onsite:0,
        target_votes:40000,
        timerCount:1800,
        timerEnabled:false,
      }
    },
    mixins: [
      mixin,
    ],
    methods:{
      logout(){
        const that = this;
        const cookie = that.$cookies;

        cookie.remove('user');
        that.$router.push('/')        
      },
      close(){
        this.break_down = false;
      },
      openBreakDown() {
        this.break_down = true;
      },
      closed(){
        this.show_branches_modal = false;
      },
      getOverAllBranch(){
        let that = this;
        that.$emit('loader');
        that.branches = false;
        election.getOverAllBranch().then(function(branches) {
          let branches_data = branches.data.data;
          that.pso_details=branches_data;
          that.timerCount=1801;
          that.timerEnabled = true;
          that.$emit('hideloader');
        });
      },
      getStatBranches(payload){
        let that = this;
        that.$emit('loader');
        that.branches = false;
        election.getBranch(payload).then(function(branches) {
          let branches_data = branches.data.data;
          let labels = [];
          let approved_data =[];
          let disapproved_data =[];
          let branches_datasets = [
              { 
                label: "APPROVED",
                data: [],
                backgroundColor: ['#016b00']
              },
              { 
                label: ["DISAPPOVED"],
                data: [],
                backgroundColor: ['#A70000']
              },
            ]
          for(let x = 0 ; x<branches_data.length;x++){
            labels.push(branches_data[x].name);
            approved_data.push(branches_data[x].total_approved);
            disapproved_data.push(branches_data[x].total_disapproved);
          }
          branches_datasets[0].data = approved_data;
          branches_datasets[1].data = disapproved_data;
          that.branch_chartConfig.labels = labels;
          that.branch_chartConfig.datasets = branches_datasets;
          that.show_branches_modal = true;
          that.branches =true;
          that.$emit('hideloader');
        });
      },
      getStats(){
          let that = this;
        that.$emit('loader');
        that.over_all = false;
        election.getStat().then(function(response) {
            let data = response.data.data;
            // console.log(data);
            that.total_members = data.total_mev;
            that.approve_mobile=data.mobile.total_approved;
            that.approve_web=data.web.total_approved;
            that.approve_onsite=data.pso.total_approved;
            that.disapprove_mobile=data.mobile.total_disapproved;
            that.disapprove_onsite=data.pso.total_disapproved;
            that.disapprove_web=data.web.total_disapproved;
            that.non_mev_mobile=data.mobile.total_non_mev;
            that.non_mev_web=data.web.total_non_mev;
            that.non_mev_onsite=data.pso.total_non_mev;
            that.total_approved = data.total_approved;
            that.total_disapproved = data.total_disapproved;
            that.total_mobile = data.mobile.total_count;
            that.total_web = data.web.total_count;
            that.total_onsite = data.pso.total_count;
            that.target_status_mev = data.mobile.total_mev + data.web.total_mev + data.pso.total_mev;
            that.target_status_non_mev = data.mobile.total_non_mev + data.web.total_non_mev + data.pso.total_non_mev;
            that.current_timestamp = response.data.timestamp;
            that.as_of_date_time = moment(response.data.timestamp).format("MMMM DD, YYYY hh:mm A");
            let payload= {
                        labels: [ 'TOTAL APPROVED', 'TOTAL DISAPPROVED' ],
                        datasets: [ { data: [data.total_approved,data.total_disapproved],
                        backgroundColor: ['#016b00','#A70000'] 
                        } ]
                      }
            let payload_regular_member =  {
                                            labels: [ 'ONSITE', 'MOBILE','WEB'],
                                            datasets: [ { data: [data.pso.total_mev,data.mobile.total_mev,data.web.total_mev],
                                            backgroundColor: ['#A9E2FD', '#FBD603','#A70000'] 
                                            } ]
                                          }
            let payload_associate_member ={
                 labels: [ 'ONSITE', 'MOBILE','WEB'],
                  datasets: [ { data: [data.pso.total_non_mev,data.mobile.total_non_mev,data.web.total_non_mev],
                  backgroundColor: ['#A9E2FD', '#FBD603','#A70000'] 
                  } ]
            }
            let approved_data =[];
            let disapproved_data= [];
            let labels=[];
            let post_back_data = data.daily;
            let data_set = [
              { 
                label: "VOTED",
                data: [],
                backgroundColor: ['#016b00']
              },
              { 
                label: ["REGISTERED"],
                data: [],
                backgroundColor: ['#FBD603']
              },
            ]
            for(let x=0;x<post_back_data.length;x++) {
                labels.push(post_back_data[x].date);
                approved_data.push(post_back_data[x].voted);
                disapproved_data.push(post_back_data[x].registered);
            }
            data_set[0].data = approved_data;
            data_set[1].data = disapproved_data;
            if(post_back_data.length>7){
              that.disable_second_week = false;
            }
            if(post_back_data.length>7){
              that.disable_second_week = false;
            }
            if(post_back_data.length>14){
              that.disable_third_week = false;
            }
            if(post_back_data.length>21){
              that.disable_fourth_week = false;
            }
            if(post_back_data.length>28){
              that.disable_fift_week = false;
            }
            if(post_back_data.length>35){
              that.disable_sixth_week = false;
            }
            if(post_back_data.length>42){
              that.disable_seventh_week = false;
            }
            that.chartConfig.labels =labels;
            that.chartConfig.datasets =data_set;
            that.doughnutConfig = payload;
            that.doughnut_config_regular = payload_regular_member;
            that.doughnut_config_associates = payload_associate_member;
            that.over_all = true;
            that.getOverAllBranch();
      });
        
      }
    },
    watch:{
    timerCount: {
      handler(value) {
        const that = this;
        if (value > 0 && that.timerEnabled) {
          that.show_resend_otp = false;
          setTimeout(() => {
            that.timerCount = that.timerCount - 1;
            // console.log(this.timerCount);
          }, 1000);
        } else {
          if(that.timerCount == 0){
            that.timerEnabled = false;
            that.logout();
          }
           // run your function here
        }
      },
      immediate: true
    },
    }
   
})
</script>
<style scoped>
.modal_max_width{
  max-width: 350px;
}
.contact-w {
    width: 100px !important;
    min-width: 120px !important;
}
.minimum-table {
  min-width: 350px;
}
.minimum-small{
  min-width: 750px;
}
.max-button-width {
  max-width: 90px;
}
.max-progress-width{
  max-width: 270px;
}
.background-web {
  background-color: #A70000;
}
.background-mobile {
  background-color: #FBD603;
}
.background-onsite {
  background-color: #A9E2FD;
}
.background-regular {
  background-color:#FAFAFB;
}
</style>
