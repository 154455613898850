<template>
    <div class="w-full flex flex-col pt-1">
        <div class="flex flex-row shadow-lg "  :class="['input-field__input input-field-wrapper',{'input-field__input--focused': on_focus_theme},{'input-field__input--error': has_error}]">
           <input type="text" placeholder="+63" v-show="text_type=='number'" disabled class="w-full max-w-xxs text-center input-field__static"/>
            <input 
                :id="id" 
                :type="text_type=='mobile'?'number':text_type" 
                v-model="curr_val"
                @keyup.enter="enter"
                @focus="onFocus"
                @blur="onBlur"
                class="w-full max-w-full bg-transparent appearance-none leading-6 p-3 text-gray-700 text-base focus:outline-none"  
            />
            <div
                @click="clickPlaceholder"
                :class="[
                    'input-field__placeholder',{'input-field-number__placeholder--focused':floatOn && text_type=='number'},
                    { 'input-field__placeholder--focused': floatOn },{'input-field-number__placeholder ':text_type == 'number'}
                ]"
                >
                {{ placeholder }}
            </div>
            <div v-if="this.has_icon" class="px-5 -py-2">
                <div class="w-icon-24 h-icon-24 peek-margin">
                    <Icons id="textbox_icon" v-bind:source="getImgSrc()" @click="togglePeeker" :width="24" />
                </div>
            </div>
          </div>
        <div v-if="strength && curr_val" class="mt-2">
          <PasswordStrength v-bind:value="curr_val" @score="passwordStrength" />
        </div>
    </div>
 
</template>
<script>
import { defineComponent } from 'vue';
import Icons from './Icon.vue';
import PasswordStrength from './PasswordStrength.vue';
export default defineComponent({
    components:{
      Icons,
      PasswordStrength
    },
    props:{
            id: {
                type:String,
                required:true,
            },
            type: {
                type:String,
                required:false,
                default:'text'
            },
            placeholder: {
                type:String,
                required:false,
            },
            initial:{
                type:String,
                required:false
            },
            has_error: {
                type:Boolean,
                required:false,
            },
            peekable: {
                type: Boolean,
                required: false,
                default: false,
            },
            strength: {
                type: Boolean,
                required: false,
                default: false,
            },
    },
    data(){
        return {
            curr_val:'',
            value:'',
            text_type:'text',
            isFocused:false,
            has_icon: false,
            on_focus_theme:false,
            visible: false,
            icon:'',
        }
    },
    watch: {
        curr_val(value) {
          if(value){
            this.$emit("changed", {
                key: this.id,
                value: value,
            });
            this.value = value;
          }
        }
    },
    mounted(){
        if(this.type) {
            this.text_type = this.type;
        }
         if(this.type == "Password" && this.peekable)
          {
              this.has_icon = true;
          }
        this.curr_val = this.initial;
    },
    
    methods:{
        getImgSrc() {
            return !this.visible ? "images/papsi_invi_icon.svg" : "images/papsi_vis_icon.svg";
        },
        clickPlaceholder() {
            this.isFocused = true;
        },
        passwordStrength(n) {
            this.$emit('score', n);
        },
        onFocus() {
            this.isFocused= true;
            this.on_focus_theme = true;
        },
        enter() {
            this.$emit('enter');
        },
        onBlur() {
            this.isFocused= false;
            this.on_focus_theme = false;
        },
        getValue() {
            return this.value != null ? this.value : this.curr_value;
        },
        togglePeeker() {
            if(this.visible)
            {
                this.text_type = "password";
                this.visible = false;
            }
            else
            {
                this.text_type = "text";
                this.visible = true;
            }
        },
        
    },
    computed: {
        floatOn() {
          if(this.curr_val){
            return this.isFocused || this.value.toString().length > 0;
          }else {
            return this.isFocused;
          }
          
        },
    },

});
</script>
<style scoped>
.input-field__input {
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border: 2px solid #bcbcbc;
  border-radius: 5px;
  outline: none;
}
.input-field__static {
  width: 100%;
  margin-top: -10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border-right: 2px solid #bcbcbc;
  outline: none;
}
.input-field__input--error {
  border: 2px solid #f00f0f;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.input-field-wrapper {
  position: relative;
  padding-top: 10px;
}
.input-field__input {
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  border: 2px solid #bcbcbc;
  border-radius: 5px;
  outline: none;
}
.input-field__input--focused {
  border: 2px solid #016b00;
}
.input-field__input--empty {
  border: 2px solid #db0404;
}
.input-field__placeholder {
  position: absolute;
  font-size: 18px;
  left: 12px;
  top: 23px;
  padding: 0 5px;
  background: transparent;
  transition: all 0.3s;
  line-height: 12px;
}
.input-field-number__placeholder {
  position: absolute;
  font-size: 18px;
  left: 85px;
  top: 23px;
  padding: 0 5px;
  background: transparent;
  transition: all 0.3s;
  line-height: 12px;
}
.input-field__placeholder--focused {
  top: 5px;
  font-size: 14px;
  left: 2px;
}
.input-field-number__placeholder--focused {
  top: 5px;
  font-size: 14px;
  left: 85px;
}
.peek-margin {
    margin-top: 11px;
}
</style>
