<template>

  <ModalControl 
    v-show="show_modal" 
    v-bind:display="show_modal" 
    v-bind:title="lang(title)"
    closable 
    id='Modal'
    :z_value="'z-50'"
    :default_width="default_width" 
    @closed="closed"
    >
      <div class="w-full ">
        <Privacy v-show="show_privacy"/>
        <TermsView v-show="show_terms"/>
        <HelpCenterView v-show="show_contact"/>
      </div>
  </ModalControl>
  <Container flex_direction='flex-row'>
    <LoadingControl v-show="loading" :loading="loading"/>
      <!-- <SidebarView 
        v-show="false" 
        :display="this.show_sidebar"
      /> -->
    <Container flex_direction="flex-col relative">  
          <NavBarView v-show="this.show_bars" class="h-14" @sidebar="sidebarTriggered"/>
          <RouterView class="overflow-y-auto"
                @loadSideBar="loadSideBar"
                @loader="loader"
                @hideloader="hideloader"
                @OpenTerms="clicked('Terms')"
                @OpenPrivacy="clicked('Privacy')"
          />
          <FooterView :copy_right_text="lang('copy.right.text')" :clickables="clickables" @clicked="clicked"/>
    </Container>
  </Container>
</template>

<script>
import { defineComponent } from "vue";
import mixin from "@/mixin";
import FooterView from "./components/sections/FooterView.vue";
// import User from "./services/User";
// import System from "./services/System";
import ModalControl from "@/components/controls/ModalControl.vue";
import Privacy from "@/components/sections/PrivacyPolicyView.vue";
import TermsView from "@/components/sections/TermsOfUserView.vue";
import HelpCenterView from "@/components/sections/ContactUsView.vue";
// import SidebarView from "@/components/controls/SidebarView.vue";
import NavBarView from "@/components/controls/NavBarView.vue";
import Container from "@/components/sections/ContainerView.vue";
import LoadingControl from "@/components/controls/LoadingControl.vue";
export default defineComponent({
  name: "App",
  mixins:[
    mixin,
  ],
  components: {
    Container,
    FooterView,
    Privacy,
    TermsView,
    ModalControl,
    HelpCenterView,
    LoadingControl,
    // SidebarView,
    NavBarView,
  },
  data() {
    return {
      show_modal:false,
      loading:false,
      show_privacy:false,
      show_terms:false,
      show_contact:false,
      logged:false,
      show_bars:false,
      show_sidebar:false,
      title:'',
      default_width:'',
      clickables:['Privacy','Terms','Contact Us'],
      noframe:'',
      back_path:'',
      screen_title: {
        value:'',
      },
      route: {
          auth:'',
          passive:'',
          path:'',
          navbar:'',
      }
    }
  },
  methods: {
    sidebarTriggered()  {
      if(this.show_sidebar==true) {
        this.show_sidebar=false;
      }else{
        this.show_sidebar=false;
      }
    },
    hideloader(){
      this.loading = false;
    },
    loadSideBar() {
        this.show_bars = true;
    },
    clicked(n) {
      if(n == 'Privacy') {
        this.show_privacy =true;
        this.show_modal=true;
        this.title = ('privacy.policy.header.text');
        this.default_width = 'max-w-5xl'
      }
      if(n == 'Terms') {
        this.show_terms = true;
        this.show_modal = true;
        this.title = ('terms.header.text');
        this.default_width = 'max-w-5xl'
      }
      if(n == 'Contact Us') {
        this.show_contact = true;
        this.show_modal = true;
        this.title = ('contact.header.text');
        this.default_width = 'max-w-3xl'
      }
    },
    closed() {
      this.show_privacy = false;
      this.show_contact = false;
      this.show_terms = false;
      this.show_modal = false;
    },
    loader(){
      this.loading = true;
    }
  },
  watch: {
    $route(to) {
      this.noframe = (to.path == "/");
      this.back_path = '';
      this.screen_title.value = to.meta.title;
      this.route.auth = to.meta.auth;
      this.route.passive = to.meta.passive;
      this.route.path = to.path;
      this.route.navbar = to.meta.navbar;
    },
  },
});
</script>

<style scoped>
.body::-webkit-scrollbar{
  display: none;
}

</style>
