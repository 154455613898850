<template>
  <Container :back_ground_color="'bg-gray-400'">
      <Card max_width="max-w-4xl" >
        <Container class="h-fit space-y-2" back_ground_color="bg-white">
            <span class="text-lg font-bold" v-text="lang('permanent.address')"/> 
            <TextField id="Rstreet" type='text' :placeholder="lang('street.address')"/>
            <Container class="h-fit space-x-2" flex_direction='flex-row' back_ground_color="bg-white" >
              <TextField id="Rbrngy" type='text' :placeholder="lang('barangay')"/>
              <TextField id="Rzip" type='text' :placeholder="lang('zip.code')" class="max-w-sm"/>
            </Container>
            <TextField id="RProvince" :placeholder="lang('province')" type='text'/>
            <TextField id="RCity" :placeholder="lang('city')" type='text'/>
         </Container>
          <Container class="h-fit space-y-2" back_ground_color="bg-white">
              <span class="text-lg font-bold pt-2" v-text="lang('secondary.address')"/> 
              <TextField id="Sstreet" type='text' :placeholder="lang('street.address')"/>
              <Container class="h-fit space-x-2" flex_direction='flex-row' back_ground_color="bg-white" >
                <TextField id="Sbrngy" type='text' :placeholder="lang('barangay')"/>
                <TextField id="Szip" type='text' :placeholder="lang('zip.code')" class="max-w-sm"/>
              </Container>
              <TextField id="Sprovince" :placeholder="lang('province')" type='text'/>
              <TextField id="Scity" :placeholder="lang('city')" type='text'/>
         </Container>
        
      </Card>
  </Container>  
</template>
<script>
import { defineComponent } from 'vue'
import Container from '@/components/sections/ContainerView.vue';
import Card from '@/components/controls/CardView.vue';
import TextField from '@/components/controls/TextBoxField.vue';
import mixin from "@/mixin";

export default defineComponent({
  components:{
    Container,
    Card,
    TextField
  },
   mixins: [
    mixin,
  ],
})
</script>
<style scoped>

</style>
