<template>
    <div v-bind:id="id" v-show="show" class="w-full h-screen bg-translucent fixed left-0 top-0 flex m-auto" :class="[z_value]"> 
        <div class="bg-white m-auto w-full rounded-md overflow-hidden transition delay-100 duration-500 ease-in-out " 
        v-bind:class="[{'-translate-x-0' : this.popUp ,'-translate-y-full':!this.popUp ,},default_width]">
            <div>
                <div class="p-3 text-md font-bold flex flex-row relative bg-theme-primary text-white" v-if="this.closable">
                    <div class="truncate uppercase grow " v-text="title"></div>
                    <div class="text-md mr-2 cursor-pointer" @click="close" v-if="this.closable">
                     X
                    </div>
                </div>
                <div class="w-full flex justify-center p-5" v-if="this.error || this.success || this.note">
                    <Icon id="pencil" :source="getSource()" :width="this.icon_size" />
                </div>
                <div class="overflow-y-auto modal-max-h text-center h-full">
                    <slot></slot>
                    <div class="flex flex-row space-x-5 p-3" v-show="this.amendment">
                        <div class="w-full ">
                            <Checkbox :checkbox_width="50" id="scheckbox" class="float-right" ref="fcheckbox" @click="checkStatus('fcheckbox')">
                                <span v-text="lang('checkbox.label.yes')" class="text-black font-bold"/>
                            </Checkbox>
                        </div>
                        <div class="w-full">
                            <Checkbox :checkbox_width="50" id="scheckbox" class="float-left" ref="scheckbox" @click="checkStatus('scheckbox')">
                               <span v-text="lang('checkbox.label.no')" class="text-black font-bold"/>
                            </Checkbox>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap flex-col p-2 border-t border-gray-300 space-y-2" v-if="positiveButton || negativeButton">
                    <div class="w-full flex-1" v-if="positiveButton !== null" >
                        <Button @clicked="positiveClick" :button_text="positiveButton" :disabled="disable_button">
                            {{ positiveButton }}
                        </Button>
                    </div>
                    <div class="w-full flex-1" v-if="negativeButton !== null">
                        <Button theme="error" @clicked="negativeClick" :button_text="negativeButton">
                            {{ negativeButton }}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import mixin from '@/mixin';
import { defineComponent } from 'vue'
import Button from "./ButtonControl.vue";
import Checkbox from "./CheckBox.vue";
import Icon from "./Icon.vue"

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true,
        },
        default_width: {
            type:String,
            default:'max-w-md',
            required:false,
        },
        type:{
            type: String,
            required: false,   
        },
        closable: {
            type: Boolean,
            required: false,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        display: {
            type: Boolean,
            required: false,
            default: false,
        },
        positiveButton: {
            type: String,
            required: false,
            default: null,
        },
        amendment:{
            type: String,
            required: false,
            default: null,
        },
        z_value:{
            type: String,
            required: false,
        },
        negativeButton: {
            type: String,
            required: false,
            default: null,
        },
        otpType: {
            type: String,
            required: false,
        },
        withcomponent:{
            type: Boolean,
            default:false,
            required: false,
        },
        success:{
            type: Boolean,
            default:false,
        },
        error:{
            type:Boolean,
            default:false,
        },
        note:{
            type: Boolean,
            default:false,
        }
    },
     components: {
        Button,
        Checkbox,
        Icon,
    },
     data() {
        return {
            show: true,
            popUp: false,
            re_reques_disabled:false,
            request_timer:120,
            icon_size:0,
            disable_button:false,
            approved_checkbox:false,
            disapproved_checkbox:false,
            Loading:false,
        }
    },
    mixins: [
      mixin,
    ],
    methods :{
        close() {
            this.show = false;
            this.$emit('closed');
        },
        positiveClick() {
        const ref = this.$refs;
        let vote;
            if(this.amendment){
                if(ref.scheckbox.status) {
                   vote=false;
                   ref.scheckbox.toggle();
                }
                if(ref.fcheckbox.status)
                {
                  vote=true;
                  ref.fcheckbox.toggle();
                }
                 this.$emit('positiveClick',vote);
            }else{
              this.$emit('positiveClick');
            }
        },
        readAmendments(){
            this.$emit('readAmendments');
        },
        getSource() {
            if(this.success){
                this.icon_size = 130;
                return 'icons/papsi_success_icon.svg';
            }
            if(this.error) {
                this.icon_size = 180;
                return 'icons/papsi_error_icon.svg';
            }
            if(this.note) {
                this.icon_size = 180;
                return 'icons/papsi_warning.svg';
            }
        },
        checkStatus(n) {
        const ref = this.$refs;

           if(n=='fcheckbox'){
                this.approved_checkbox =true;
                if(ref.scheckbox.status) {
                    this.disapproved_checkbox = false;
                    ref.scheckbox.toggle();
                }
           }
           if(n=='scheckbox'){
            this.disapproved_checkbox = true;
              if(ref.fcheckbox.status)
                {
                this.approved_checkbox =false;
                  ref.fcheckbox.toggle();
                }
           }
           if(ref.fcheckbox.status == false && ref.scheckbox.status == false){
                this.disable_button = true;
           }else{
                this.disable_button = false;
           }
        }
    },
    watch: {
        display(n) {
            this.show = n;
            setTimeout(() => {
                this.popUp = n;
            }, 10);
        }
    },
    mounted(){
        this.show = this.display;
        if(this.amendment){
            if(this.approved_checkbox == false && this.disapproved_checkbox == false){
                this.disable_button = true;
           }
        }
    }
})
</script>
<style scoped>
.bg-translucent {
    background-color: rgba(0, 0, 0, 0.75);
}
.modal-max-h {
    max-height: calc(100vh - 50px);
}
</style>