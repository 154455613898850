<template>
    <div class="w-full bg-gray-300 p-2 h-12 flex flex-row">
        <span  v-text="copy_right_text" class="self-center"/>
        <div class="grow" />
        <div class="space-x-3 self-center cursor-pointer">
            <span v-for="(item,index) in clickables" :id="item+index" :key="index" v-text="item" @click="clicked(item)" class="hover:underline"/>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props:{
        copy_right_text:{
            type:String,
            required:true,
        },
        clickables:{
            type:Array,
            required:false,
        }
    },
    methods:{
        clicked(item) {
            this.$emit('clicked',item);
        }
    }
})
</script>
<style scoped>

</style>
