<template>
   <ContainerView height="p-10 h-screen">
        <CardView max_width="max-w-5xl m-auto " :title="lang('create.an.account')" v-show="show"
        :class="[{'-translate-x-0 opacity-100' : this.fade ,'-translate-y-full opacity-0':!this.fade ,},'transition delay-100']">
          <div class="w-full flex flex-col p-5 ">
                <div class="flex flex-row w-full space-x-2">
                    <TextBoxField @changed="registerFields" id="last_name" type="text" placeholder="Last Name"/>
                    <TextBoxField @changed="registerFields" id="first_name" type="text" placeholder="First Name"/>
                    <TextBoxField @changed="registerFields" id="middle_name" type="text" placeholder="Middle Name"/>
                </div>
                <div class="flex flex-row space-x-2">
                <TextBoxField @changed="registerFields" id="account_number" type="text" placeholder="Account No."/>
                <TextBoxField @changed="registerFields" id="serial_number" type="text" placeholder="Serial No."/>
                </div>
                <div class="flex flex-col">
                    <TextBoxField @changed="registerFields" id="email" type="text" placeholder="Email" />
                    <TextBoxField @changed="registerFields" id="mobile_number" type="number" placeholder="Mobile no."/>
                    <div class="text-md flex text-start pt-1 text-gray-400 w-full">
                        <span v-text="lang('username.instructions')"/>
                    </div> 
                    <TextBoxField @changed="registerFields" id="username" type="text" placeholder="Username"/>
                    <div class="text-md flex text-start pt-1 text-gray-400 w-full">
                        <span v-text="lang('password.instructions')"/>
                    </div>
                    <TextBoxField strength peekable @changed="registerFields" id="password" type="Password" placeholder="Password"/>
                    <TextBoxField peekable @changed="registerFields" id="confirm_password" type="Password" placeholder="Confirm Password"/>
                </div>  
            <div>
                <div class="flex flex-row space-x-2 pt-2">
                    <Checkbox id="agree_ck" class="text-left" @changed="aggree"/>
                    <div class="flex flex-row space-x-2 mt-auto mb-auto">
                        <span v-text="'I agree with the'"/>
                        <LinkText emit_triggered='OpenTerms' @OpenTerms="OpenTerms" class="text-theme-primary font-bold" text="End User License Agreement"></LinkText>
                        <span v-text="'and'"/> 
                        <LinkText emit_triggered='OpenPrivacy' @OpenPrivacy="OpenPrivacy" class="text-theme-primary font-bold" text="'Privacy Policy'"></LinkText>
                    </div>
                </div>
                <div class="w-full flex justify-center pt-2 pb-2">
                    <VueRecaptcha v-bind:sitekey="key" size="32" />
                    </div>
                    <Button :button_text="'REGISTER'" @clicked="register" :disabled="this.reg_button_disable"/>
                    <div class="flex-grow text-center p-1">
                        <LinkText :text="lang('already.have.an.account')" bold link='/' text_size='text-md'/>
                    </div>
            </div>
          </div>
        </CardView>
    </ContainerView>
</template>
<script>
import { defineComponent } from 'vue'
import User from "@/services/User";
import TextBoxField from "@/components/controls/TextBoxField.vue";
import ContainerView from "@/components/sections/ContainerView.vue";
import CardView from "@/components/controls/CardView.vue";
import Checkbox from "@/components/controls/CheckBox.vue";
import LinkText from "@/components/controls/LinkText.vue";
import Button from '@/components/controls/ButtonControl.vue';
import { VueRecaptcha } from "vue-recaptcha";
import mixin from "@/mixin";


export default defineComponent({
    components:{
        TextBoxField,
        ContainerView,
        CardView,
        Checkbox,
        LinkText,
        VueRecaptcha,
        Button
    },
    data(){
        return{
            key:'6LdTAAQhAAAAAApdt61xDVJaAUU5Cv6opGbLJFFT',
            show:false,
            fade:false,
            reg_button_disable: true,
            reg_info:{
            last_name:'',
            middle_name:'',
            first_name:'',
            account_number:'',
            serial_number:'',
            email:'',
            mobile_number:'',
            username:'',
            password:'',
            confirm_password:'',
            aggree:false,
            score:0,
            },
        }
        
    },
    mixins: [
     mixin,
    ],
    mounted() {
        this.show=true;
    },
    methods:{
        OpenTerms() {
          this.$emit('OpenTerms');
        },
        OpenPrivacy() {
          this.$emit('OpenPrivacy');
        },
        clicked() {
         this.$router.push('/register/validate');
        },
        register() {
        let payload;
        let that = this;
        that.loading = true;
        that.show_registration_modal = false;
        // this.show_poll_modal = true;
        payload = {
           first_name:that.reg_info.first_name, 
           last_name:that.reg_info.last_name, 
           middle_name:that.reg_info.middle_name, 
           account_no:that.reg_info.account_number, 
           serial_no:that.reg_info.serial_number, 
           email:that.reg_info.email, 
           mobile:'+639'+that.reg_info.mobile_number, 
           username:that.reg_info.username,
           password1:that.reg_info.password, 
           password2:that.reg_info.confirm_password
        }
        User.signup(payload).then(function() {
             User.createUser(payload).then(function(response) {
                that.uuid = response.data.data.uuid;
                  User.getuserInfo(that.uuid).then(function(response) {
                      let data = response.data.data;
                      if(data.election.can_vote) {
                        that.success = true;
                        that.show_message_modal = true;
                        that.modal_message = "You've Successfully Registered";
                        that.modal_type = 3;
                      }
                      if(!data.election.can_vote) {
                        that.success = true;
                        that.modal_type = 1;
                        that.show_message_modal = true;
                        that.modal_message = "You've Successfully Registered";
                      }  
                      that.loading = false;
                  }).catch(function(error) {
                      that.error = true;
                      that.modal_type = 2;
                      that.show_message_modal = true;
                      that.modal_message = error.response.data.message;
                      that.loading= false;
                  });
            }).catch(function(error) {
              that.loading= false;
              that.modal_type = 2;
              that.error = true;
              that.show_message_modal = true;
              that.modal_message = error.response.data.message;
              
            });
        }).catch(function(error) {
          that.loading= false;
          that.modal_type = 2;
          that.error = true;
          that.show_message_modal = true;
          that.modal_message = error.response.data.message;
          
        });
      },
        aggree(n) {
        let that = this;
        that.reg_info.aggree = n;
         if(that.reg_info.first_name && 
          that.reg_info.last_name && 
          that.reg_info.username &&
          that.reg_info.password &&
          that.reg_info.confirm_password &&
          that.reg_info.email &&
          that.reg_info.mobile_number &&
          that.reg_info.account_number && 
          that.reg_info.serial_number &&
          that.reg_info.aggree) {
            that.reg_button_disable=false; 
        }
      },
         registerFields(e){
            let that = this;
            let obj = that.reg_info;
            obj[e.key] = e.value;

            if(that.reg_info.first_name && 
            that.reg_info.last_name && 
            that.reg_info.username &&
            that.reg_info.password &&
            that.reg_info.confirm_password &&
            that.reg_info.email &&
            that.reg_info.mobile &&
            that.reg_info.account_number && 
            that.reg_info.serial_number &&
            that.reg_info.aggree) {
                that.reg_button_disable=false; 
            }
      },
    },
    watch:{
        show() {
             setTimeout(() => {
                this.fade=true;
            }, 100);
        }
    }
})
</script>
