import { createStore } from "vuex";

export default createStore({
  state: {
    user_credentials: {
      name : null,
      username : null,
    }
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
